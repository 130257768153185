.border-animation {
  position: relative;
  overflow: hidden;
}

.border-animation::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border: 2px solid transparent;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.65) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-size: 200% 200%;
  animation: animate-border 4s linear infinite;
}

@keyframes animate-border {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
}

@keyframes moveBackgroundTopToBottom {
  0% {
    background-position: 0 0%;
  }
  100% {
    background-position: 200% 0;
  }
}

.moving-background {
  background-repeat: repeat-x;
  background-size: auto 100%;
  animation: moveBackgroundTopToBottom 30s linear infinite;
}

@media (max-width: 768px) {
  .moving-background {
    animation: moveBackgroundTopToBottom 60s linear infinite;
  }
}
