.survey-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  header {
    background-color: #282c34;
    padding: 20px;
    color: white;

    nav {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
        display: flex;
        align-items: center;

        img {
          height: 40px;
          vertical-align: middle;
          margin-right: 10px;
        }

        h1 {
          font-size: 24px;
          margin: 0;
        }
      }

      .nav-links {
        list-style: none;
        padding: 0;
        display: flex;

        li {
          margin: 0 10px;

          a {
            color: white;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  main {
    flex: 1;
    padding: 20px;

    #survey {
      h2 {
        margin-top: 0;
      }

      form {
        display: flex;
        flex-direction: column;
        gap: 15px;

        label {
          margin-bottom: 5px;
          font-weight: bold;
        }

        input,
        textarea {
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
          font-size: 16px;
        }

        button {
          padding: 10px 20px;
          background-color: #282c34;
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-size: 16px;

          &:hover {
            background-color: #3a3f47;
          }
        }
      }
    }
  }

  footer {
    background-color: #282c34;
    color: white;
    text-align: center;
    padding: 10px 0;
    width: 100%;
  }
}
