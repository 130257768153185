// Color and Font Settings
$primary-color: #2eb7cc; // Blue shade for primary actions
$secondary-color: #2eb7cc; // Green shade for secondary elements
$background-color: #ffff; // Light grey background for a clean look
$font-stack: "Helvetica Neue", Arial, sans-serif; // Font stack for modern typography
$font-color: #333; // Dark grey for text for better readability

// General Body Styles
body {
  font-family: $font-stack;
  color: $font-color;
  background-color: $background-color;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

// Header Styles
header {
  background-color: #2eb7cc;
  padding: 20px 0;
  display: flex; // Correct display type for flexbox
  justify-content: center; // Properly center content
  align-items: center; // Align items vertically

  h1 {
    display: flex; // Enable flexbox for H1 to manage children
    align-items: center; // Vertically align the text and logos
    color: black;
    font-size: 2.5rem; // Increased for better visibility
    justify-content: center; // Center text and logos horizontally
    gap: 20px; // Adds space between logos and the text
  }
}

.logo {
  width: 70px;
  padding-top: 8px; // Adds space above the logo
}

// Content and Layout Styles
.content {
  padding: 40px;
  max-width: 800px; // Optimal reading width
  margin: 0 auto; // Center align the content area
  flex: 1; // Allow content to grow and push footer down

  .welcome-message {
    font-size: 1.5rem;
    color: darken($font-color, 20%); // Slightly darker for emphasis
    margin-bottom: 20px; // Added margin for spacing
    margin-top: 60px;
  }

  .questionnaire {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%; // Ensure the questionnaire takes full width

    .question {
      margin-bottom: 20px; // Increased spacing for clarity

      label {
        display: block; // Make label block for better control
        font-weight: bold; // Make labels bold to stand out
        margin-bottom: 5px; // Space between label and input
      }

      input {
        width: 100%; // Full width inputs
        padding: 10px;
        border: 1px solid $primary-color;
        border-radius: 5px;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1); // Subtle inset shadow for depth

        &:focus {
          border-color: lighten(
            $primary-color,
            10%
          ); // Lighter border on focus for visibility
          box-shadow: 0 0 8px rgba($primary-color, 0.6); // Glow effect on focus
        }
      }
    }
  }
}

// Chat Bubbles Styles
.conversation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 800px;
  width: 100%; // Ensure conversation takes full width
  margin: 0 auto;
  overflow-y: auto;
  height: 60vh;
}

.message {
  display: flex;
  flex-direction: column;
  align-items: flex-start; // Ensure messages align to the start
  margin: 20px 0; // Increased spacing between bubbles
  width: 100%;
}

.question-bubble {
  align-self: flex-start;
  background-color: #cdf7f7; // Updated color
  padding: 10px;
  border-radius: 15px 15px 15px 0;
  margin-bottom: 10px; // Added margin for separation
  max-width: 80%; // Increased max width
  word-wrap: break-word;
  white-space: pre-wrap;
  color: #404245;
}

.answer-bubble {
  align-self: flex-end;
  background-color: #f2f0f0; // Updated color
  padding: 10px;
  border-radius: 15px 15px 0 15px;
  margin-bottom: 10px; // Added margin for separation
  max-width: 85%; // Increased max width
  word-wrap: break-word;
  white-space: pre-wrap;
  position: relative;
  padding-right: 32px; /* Space for the edit icon */
}

.edit-icon {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: none; /* Initially hide the icon */
  color: #8b9090;
}

.answer-bubble:hover .edit-icon {
  display: inline; /* Show the icon on hover */
}

.typing-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  span {
    display: inline-block;
    background-color: #999;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 0 4px;
    animation: typing 0.5s infinite;

    &:nth-child(1) {
      animation-delay: 0s;
    }
    &:nth-child(2) {
      animation-delay: 0.1s;
    }
    &:nth-child(3) {
      animation-delay: 0.2s;
    }
  }
}

@keyframes typing {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
  100% {
    transform: translateY(0);
  }
}

input[type="text"] {
  width: calc(100% - 30px);
  padding: 10px;
  border: 1px solid $primary-color;
  border-radius: 5px;
  margin-top: 10px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  font-size: 15px;
}

// Footer Styles
footer {
  background-color: white;
  color: #2eb7cc;
  text-align: center;
  padding: 10px 0;
  font-size: 1rem;
  margin-top: auto;
  width: 100%;
}

// Response Box Styles
.response-box {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid darken($background-color, 10%);
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  h2 {
    color: $secondary-color;
    margin-bottom: 10px;
  }
  p {
    padding: 10px;
    border-radius: 4px;
    color: #2eb7cc;
  }
}

:root {
  --outer-color: #555555; /* Gray color */
  --inner-color: #4cc1c4; /* Turquoise color */
}
:root {
  --outer-color: #555555; /* Gray color */
  --inner-color: #4cc1c4; /* Turquoise color */
}

button {
  display: inline-block;
  padding: 12px 24px;
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--inner-color);
  background-color: var(--outer-color);
  border: 2px solid var(--inner-color);
  border-radius: 50px;
  text-align: center;
  text-decoration: none;
  transition:
    background-color 0.3s ease-in-out,
    color 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &:hover {
    background-color: var(--inner-color); /* Change background to turquoise */
    color: var(--outer-color); /* Change text to gray */
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.4);
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.1); /* Light overlay */
    z-index: -1;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
  }

  &:hover::before {
    opacity: 1;
  }
}
