.unique-container_ai {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .unique-logo {
      width: 80px;
      margin-left: 10px;
    }

    .unique-header-right {
      display: flex;
      align-items: center;

      .unique-brand-toggle {
        display: flex; /* Use flex to align label and toggle side-by-side */
        align-items: center; /* Center-align the items vertically */
        margin-right: 40vw;
        width: 300px; /* Adjust width as necessary, or consider making it auto if dynamic width is needed */
        margin-bottom: 10px;

        .unique-toggle-switch {
          position: relative;
          width: 50px;
          height: 24px;
          margin-bottom: 27px;

          input {
            opacity: 0;
            width: 0;
            height: 0;
          }

          .unique-react-switch-label {
            display: block;
            overflow: hidden;
            cursor: pointer;
            height: 100%;
            border-radius: 30px;
            background-color: #ccc;
            position: relative;
            transition: background-color 0.2s;

            &:before {
              content: "";
              position: absolute;
              top: 2px;
              left: 2px;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              background-color: white;
              transition: transform 0.2s;
            }
          }

          input:checked + .unique-react-switch-label {
            background-color: #06d6a0;

            &:before {
              transform: translateX(26px);
            }
          }
        }
        .unique-brand-analysis-label {
          margin-right: 10px; /* Space between label and the toggle */
          font-weight: bold;
          color: #333;
          margin-top: 20px;
        }
      }

      .unique-buttons {
        button {
          background: #f0f0f0;
          border: 2px solid #ccc;
          padding: 8px 16px;
          font-size: 14px;
          font-weight: bold;
          cursor: pointer;
          margin-right: 10px;
          transition:
            background-color 0.3s,
            border-color 0.3s,
            color 0.3s;
          &:hover,
          &.unique-active {
            background-color: #c8e6c9;
            border-color: #66bb6a;
            color: #333;
          }

          &.unique-active {
            background-color: #4caf50; // A greener shade for the active state
            color: white;
            border-color: #4caf50;
          }
        }
      }
    }
  }

  .unique-controls {
    display: flex;
    margin-top: 120px;

    input[type="text"] {
      margin-right: 10px;
      width: 20%;
    }

    select {
      flex: 1;
      margin-right: 10px;
      padding: 10px;
      background-color: #f5f5f5;
      border: 1px solid #ddd;
    }
  }

  textarea {
    width: 100%;
    height: 150px;
    padding: 10px;
    margin-top: 20px;
    border: 1px solid #ddd;
  }

  .unique-generate {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12%;
    padding: 10px;
    background-color: #0bf;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    margin-left: 45%;

    &:hover {
      background-color: #08a;
    }
  }

  .unique-clips-container {
    display: flex;
    justify-content: space-around; /* Adjusts space between clips */
    align-items: center; /* Vertically centers the content of each clip */
    overflow-x: auto; /* Allows horizontal scrolling if clips overflow the container width */
    padding: 20px 0;
  }

  .unique-clip {
    flex: 0 0 auto;
    width: 300px;
    margin: 0 10px;
    text-align: center;
    margin-right: 3vw;

    textarea {
      width: 250px; /* Set the specific width for the description input */
      height: 50px;
      padding: 10px;
      border: 1px solid #ddd;
      margin-bottom: 10px;
      margin-top: 5px;
    }
  }

  .unique-video-placeholder {
    width: 100%; /* Makes the video placeholder take the full width of the clip container */
    height: auto; /* Maintains aspect ratio */
    margin-bottom: 10px;
  }
}
