.aicontent-container {
  display: flex;
  flex-direction: column; // Ensure both containers are side by side
  align-items: flex-start;
  // padding: 50px;
  margin-top: 4vh;
  // margin-left:-10px;
  // background-color: #f4f6f8;
  width: 90%;
  height: 100%;
  box-sizing: border-box; // Ensures padding is included in the element's total width and height

  .content-recommendations {
    display: flex;
    justify-content: flex-start; // Align boxes to the start
    align-items: center; // Align items vertically centered
    width: 100%;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    margin-bottom: 30px;

    .recommendation-box {
      flex-basis: 30%; // Set base width of each box
      flex-grow: 0; // Prevent boxes from growing
      flex-shrink: 0; // Prevent boxes from shrinking
      margin-right: 20px; // Set space between boxes
      padding: 20px;
      border: 1px solid #ddd;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #ffffff;

      &:last-child {
        margin-right: 0; // Remove margin from the last box
      }

      .refresh-button {
        border: none;
        background-color: transparent;
        cursor: pointer;
        display: flex;
        align-items: center;
        color: #007bff; // Set color for the icon
        margin: 0; // Remove all margin
        padding: 0; // Remove all padding
      }

      .dropdown-button {
        margin: 0; // Remove all margin
        padding: 0; // Remove all padding
        margin-left: 10px;
        display: flex;
        align-items: center;
        border: none;
        background: none;
        cursor: pointer;
        color: #000000; // Set appropriate color
      }
      .recommendation-text {
        font-family: "Roboto, sans-serif"; // Set desired font family
        font-size: 18px; // Set desired font size
        font-weight: 500; // Set desired font weight
        color: #333; // Set desired text color
        margin: 0;
        padding: 0;
      }
    }
  }

  .sub-idea-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 47px;
    padding: 1px;
    background-color: #f9f9f9;
    border-radius: 1px;
    cursor: pointer; // Add cursor pointer for the clickable hand

    &.selected {
      background-color: #c0c0c0; // Gray background for selected sub-idea
    }

    .sub-idea-text-container {
      flex-grow: 1;
      padding: 5px;
      transition: background-color 0.3s; // Smooth transition for background color change
      border-radius: 4px; // Add border-radius to avoid sharp corners

      &:hover {
        background-color: #c0c0c0; // Darker gray hover background color
      }
    }

    .sub-idea-text {
      color: #333; // Default text color
      margin: 0;
    }
  }

  .input-group {
    margin-bottom: 1rem; // Space between input groups
    width: 100%; // Make input fields take the full width

    .MuiFormControl-root {
      width: 100%; // Full width for the form control wrapper

      .MuiInputBase-root {
        width: 100%; // Full width for the input base
        min-height: 40px; // Minimum height for the input box
        font-size: 14px; // Font size inside the input
      }

      .MuiOutlinedInput-root {
        width: 100%;
        padding: 10px; // Padding inside the input to ensure spacing
        font-size: 14px; // Font size inside the input

        // Adjust border and appearance
        border-radius: 4px; // Rounded corners for the input field
        border: 1px solid #ccc; // Light border color

        &:hover {
          border-color: #000; // Change border color on hover
        }

        &:focused {
          border-color: #000; // Change border color on focus
        }
      }

      textarea {
        width: 100%; // Full width for text areas
        min-height: 40px; // Set the minimum height for larger text areas
        padding: 0px; // Padding inside the textarea
        font-size: 14px; // Text size inside the textarea
        resize: vertical; // Allow vertical resizing for text areas
      }
    }
  }
}
