.contact-us-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: "Arial", sans-serif;
  background-color: #f4f4f4;
  color: #333;

  header {
    background-color: #282c34;
    padding: 20px;
    color: white;

    nav {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
        display: flex;
        align-items: center;

        img {
          height: 40px;
          vertical-align: middle;
          margin-right: 10px;
        }

        h1 {
          font-size: 24px;
          margin: 0;
        }
      }

      .nav-links {
        list-style: none;
        padding: 0;
        display: flex;

        li {
          margin: 0 10px;

          a {
            color: white;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  main {
    flex: 1;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;

    section {
      background: white;
      padding: 20px;
      margin-bottom: 20px;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

      h2 {
        margin-top: 0;
      }

      form {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .form-group {
          display: flex;
          flex-direction: column;
        }

        label {
          margin-bottom: 5px;
          font-weight: bold;
        }

        input,
        textarea {
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
          font-size: 16px;
        }

        button {
          padding: 10px 20px;
          background-color: #282c34;
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-size: 16px;
          align-self: flex-start;

          &:hover {
            background-color: #3a3f47;
          }
        }
      }

      .contact-info {
        margin-top: 20px;

        p {
          margin-bottom: 10px;

          a {
            color: #007bff;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      #faq {
        .faq-item {
          margin-bottom: 20px;

          h3 {
            margin-top: 0;
          }
        }
      }

      ul {
        padding-left: 20px;

        li {
          margin-bottom: 10px;
        }
      }
    }
  }

  footer {
    background-color: #282c34;
    color: white;
    text-align: center;
    padding: 10px 0;
    width: 100%;
  }
}
