.privacy-policy-container {
  header {
    background-color: #282c34;
    padding: 20px;
    color: white;

    nav {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
        display: flex;
        align-items: center;

        img {
          height: 40px;
          vertical-align: middle;
          margin-right: 10px;
        }

        h1 {
          font-size: 24px;
          margin: 0;
        }
      }

      .nav-links {
        list-style: none;
        padding: 0;
        display: flex;

        li {
          margin: 0 10px;

          a {
            color: white;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  main {
    padding: 20px;

    #privacy-policy {
      h2 {
        margin-top: 0;
      }

      h3 {
        margin-top: 20px;
      }

      h4 {
        margin-top: 10px;
      }

      p,
      ul {
        margin-bottom: 20px;
      }

      ul {
        padding-left: 20px;

        li {
          margin-bottom: 10px;
        }
      }
    }
  }

  footer {
    background-color: #282c34;
    color: white;
    text-align: center;
    padding: 10px 0;
    width: 100%;
  }
}
