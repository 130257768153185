@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #ffffff;
  color: #333333;
}

header {
  background-color: #ffffff;
  color: #333333;
  padding: 1rem 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  border-bottom: 1px solid #dddddd;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  width: 100%;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Aligns items to the start */
  margin-left: -350px; /* Ensures it starts at the far left */
  flex: 1; /* Ensures the logo section takes up available space */
  max-width: 3px;
}

.logo img {
  height: 40px;
  vertical-align: middle;
  margin-right: 10px;
}

.logo h1 {
  font-size: 24px;
  margin: 0;
  color: #000000;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 2.5rem; /* Increased gap for more space between links */
  flex: 1; /* Ensures the nav-links section takes up available space */
  justify-content: flex-end; /* Aligns nav-links to the end */
  margin-right: 250px;
}

.nav-links a {
  color: #333333;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #3498db;
}

.cta-button {
  background-color: #3498db;
  padding: 0.5rem 1rem;
  color: #ffffff;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #2980b9;
}

.login-button {
  background-color: #cccccc;
  padding: 0.5rem 1rem;
  color: #333333;
  border-radius: 5px;
  text-decoration: none;
  margin-right: 1rem;
}

.login-button:hover {
  background-color: #aaaaaa;
}
main {
  padding-top: 5rem; /* To offset the fixed header */
}

.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5rem 2rem;
  background-color: #f9f9f9;
}

.hero-content {
  max-width: 50%;
}

.hero h1 {
  font-size: 2.5rem;
  color: #000000;
  margin-bottom: 1rem;
}

.hero p {
  font-size: 1.2rem;
  color: #666666;
  margin-bottom: 2rem;
}

.hero-image img {
  max-width: 100%;
  height: auto;
}

#features {
  padding: 3rem 1rem;
  text-align: center;
}

#features h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.feature {
  margin-bottom: 2rem;
}

#pricing {
  background-color: #ffffff;
  padding: 3rem 1rem;
  text-align: center;
}

.pricing-plan {
  margin-bottom: 2rem;
  padding: 1rem;
  border: 1px solid #dddddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.pricing-plan h3 {
  color: #3498db;
}

#testimonials {
  padding: 3rem 1rem;
  text-align: center;
  background-color: #f9f9f9;
}

.testimonial {
  margin-bottom: 2rem;
}

footer {
  background-color: #333333;
  color: #ffffff;
  text-align: center;
  padding: 1rem 0;
}
