.signup-form {
  background-color: #ffffff; // Pure white background for clarity
  position: relative; // Needed for absolute positioning of children
  border-radius: 8px; // Smoothed corners for a modern look
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); // Subtle shadow for depth
  padding: 2rem; // Adequate padding for spacing
  max-width: 55%; // Optimal width for form accessibility
  margin: 4rem auto; // Centers the form with vertical spacing
  margin-top: 125px;
  display: flex;
  flex-direction: column; // Ensures vertical stacking of fields and buttons
  align-items: center; // Aligns children (fields and buttons) in the center
  transition: box-shadow 0.3s ease-in-out; // Smooth transition for shadow
  height: 800px;
  &:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); // Enhanced shadow on hover for interactive effect
  }

  h1 {
    margin-left: 80px; // Increase top margin
  }
  .form-field {
    width: 100%; // Full width to utilize space effectively
    margin-bottom: 1rem;

    input {
      width: 250px; // Ensures input fields use full width
      border: 2px solid #ccc; // Slightly thicker border for focus
      border-radius: 4px;
      height: 40px; // Comfortable height for entry
      padding: 0 15px; // Padding inside input for text spacing
      transition: border-color 0.3s ease-in-out; // Smooth transition for border color
      // margin-right: 60px;
      &:focus {
        border-color: #0056b3; // Highlight color on focus
        outline: none; // Removes default outline to use custom border style
      }
    }

    label {
      display: block;
      margin-bottom: 5px;
      color: #333;
      font-size: 14px; // Clear and readable font size
      transition: color 0.3s ease-in-out; // Smooth transition for label color
    }
  }

  .terms {
    width: 100%; // Ensure alignment and spacing is consistent
    display: flex;
    align-items: center;
    margin: 20px 0;

    input[type="checkbox"] {
      margin-right: 10px;
    }

    label {
      cursor: pointer;
    }
  }

  button {
    width: 8%;
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 0;
    font-size: 16px;
    position: absolute;
    margin-left: 88px;
    cursor: pointer;
    transition:
      background-color 0.3s,
      transform 0.2s;

    &:hover {
      background-color: #004080;
    }

    &:active {
      transform: scale(0.98); // Subtle click effect
    }
  }

  .link-btn {
    position: absolute;
    background-color: transparent; // Optional: Different style for the link button
    color: #0056b3;
    text-decoration: underline;
    margin-top: 50px; // Space above the link button
    // padding: 10px 0;
    width: 25%;
    margin-left: 5px;

    &:hover {
      background-color: #f0f0f0; // Light background on hover for the link button
    }
  }
}

.logo {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #555;

  img {
    width: 80px;
    height: 80px;
    margin-right: 10px;
  }

  .logo h1 {
    font-size: 24px;
    margin: 0;
    color: #333; // Default color for "Soci"

    .highlight {
      color: #00c0c0; // Color for "Aloha"
    }
  }
}
