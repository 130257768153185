.container-root {
  padding: 10px !important; /* Remove padding */
  margin: 0 !important; /* Remove margin */
}

.device-container {
  display: flex;
  justify-content: flex-start;
  padding: 0px;
  width: 450px;
  margin-bottom: 40px;
}

.instagram-post {
  width: 90%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.post-header,
.post-footer {
  padding: 10px;
  background: white; /* Ensure footer background is white */
}

.post-header {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.profile-pic {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.post-user-info {
  display: flex;
  flex-direction: column;
}

.post-image {
  flex-grow: 1;
  margin-left: 30px;
}

.post-image img {
  width: 30vh;
  height: 45vh;
  object-fit: cover;
}

.post-footer {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.post-interactions {
  display: flex;
  justify-content: space-around;
  margin-bottom: 5px;
}

.bold-text {
  font-weight: bold;
}

.post-caption,
.post-hashtags,
.post-comments {
  margin-bottom: 5px;
}

.media-card {
  width: 49vh; /* Set a specific width */
  height: 72.7vh; /* Set a specific width */
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
}

.general-statistics {
  flex-grow: 1;
  margin-left: 30px;
}

.general-statistics-title {
  margin-bottom: 10px;
}

.general-statistics-card {
  padding: 10px;
  text-align: center;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: auto;
}

.general-statistics-card .stat-value {
  font-size: 20px;
  font-weight: bold;
}

.general-statistics-card .stat-title {
  font-size: 14px;
  color: #666;
}

.button-group-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
}

.sort-by-selector {
  margin-right: 20px;
  min-width: 150px;

  .MuiInputLabel-root {
    color: #666;
    transform: translate(14px, 10px) scale(1); /* Adjust label position */
  }

  .MuiSelect-root {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;

    &:focus {
      border-color: #3f51b5;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: #ddd;
  }

  .MuiSelect-icon {
    color: #3f51b5;
  }
}

.button-groups {
  display: flex;
  align-items: center;
  margin-right: 50px;

  .MuiButtonGroup-root .MuiButton-root {
    border-color: #ddd;
    color: #666;

    &.selected {
      background-color: #3f51b5;
      color: #fff;
    }
  }
}
